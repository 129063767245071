import { PlaceObject } from '@eventbrite/discover-utils';
import { Layout } from '@eventbrite/eds-layout';
import { isMediumNarrowDown } from '@eventbrite/eds-utils';
import { HAS_WINDOW } from '@eventbrite/feature-detection';
import { checkGate, logEvent, updateUser } from '@eventbrite/statsig';
import loadable from '@loadable/component';
import React from 'react';
import { connect } from 'react-redux';
import {
    Bucket,
    ConnectedProps,
    DiscoverState,
    FeatureFlags,
} from '../../../../types/index';
import { isLocalStorageSupported } from '../../../../utils/localStorage';
import { BasePage } from '../../../base';
import { STATSIG_HOME_ANY_CLICK } from '../../constants/analytics';

import AttendeeTicketNotification from '@eventbrite/attendee-ticket-notification';
import { FormattedEvent } from '@eventbrite/event-renderer';
import { HydratedDiv } from '../../../../components/HydratedDiv/HydratedDiv';
import { selectSearchBarProps } from '../../../../redux/selectors/location';
import { updateHeaderImage } from '../../redux/header';
import { getRestOfContext } from '../../redux/root';
import { runSearch } from '../../redux/search';
import { BrowseContainer } from '../BrowseContainer';
import { HomeBottomShelfContainer } from '../HomeBottomShelfContainer';
import { HalloweenBannerCarousel } from '../HomepageBannerCarousel';
import './HomeBaseContainer.scss';

const HeaderContainer = loadable(() => import('../HeaderContainer'), {
    resolveComponent: (components: { HeaderContainer: React.ReactElement }) =>
        components.HeaderContainer,
    fallback: <div style={{ height: '300px' }}></div>,
});

const FullbleedHeaderContainer = loadable(
    () => import('../FullbleedHeaderContainer'),
    {
        resolveComponent: (components: {
            FullbleedHeaderContainer: React.ReactElement;
        }) => components.FullbleedHeaderContainer,
        fallback: <div className="fullbleed-header-fallback"></div>,
    },
);

const RecFeedbackContainer = loadable(() => import('../RecFeedbackContainer'));

const isFeatureFlagRecFeedbackStatusPass = checkGate('rec_feedback');

const handleOnClick = () => {
    logEvent(STATSIG_HOME_ANY_CLICK);
};

//Connect BasePage with extra content
const _mapStateToProps = (state: DiscoverState, ownProps: any) => {
    const { app, user, content } = state;
    return {
        ...ownProps,
        isMobile: app.isMobile,
        isAuthenticated: user.isAuthenticated,
        userId: user.publicId,
        featureFlags: app.featureFlags,
        seasonalContent: content.seasonal,
        searchBar: selectSearchBarProps(state),
    };
};

const _mapDispatchToProps = {
    handleSearch: runSearch,
};

const ConnectedBasePage = connect(
    _mapStateToProps,
    _mapDispatchToProps,
)(BasePage);

// Deal with the messed up Varnish urls (/esi_cahe/?...) and (/fe/) which breaks the signin link
const fixLoginUrl = (loginUrl: string) => {
    const splitUrl = loginUrl.split('/');
    const urlBeginning = splitUrl.slice(0, -1);

    return urlBeginning.concat('?referrer=%2F').join('/');
};

interface OwnProps {
    buckets?: Bucket[];
    featureFlags?: FeatureFlags;
    location: Location;
    placeId?: string;
    latitude?: number;
    longitude?: number;
    currentPlace?: string;
    currentPlaceParent?: string;
    locations?: {
        places: PlaceObject[];
    };
    placeType?: string;
    slug?: string;
    flatBucket?: {
        results: FormattedEvent[];
    };
    handleRestOfContext?: Function;
    originalContext?: object;
    history?: object;
    updateHeaderImage?: Function;
    enable_experimentation?: boolean;
    should_enable_experimentation?: boolean;
    isGDPRCountry?: boolean;
}

interface HomeBaseContainerState {
    isInBrowser?: boolean;
    isMobile?: boolean;
    isLocalStorageRecFeedbackViewed?: boolean;
    showStickyCategoryBrowse: boolean | undefined;
}

export class HomeBaseContainer extends React.Component<
    HomeBaseContainerProps,
    HomeBaseContainerState
> {
    constructor(props: HomeBaseContainerProps) {
        super(props);

        this.state = {
            isInBrowser: false,
            isMobile: false,
            showStickyCategoryBrowse: undefined,
        };
        props.updateHeaderImage?.();
    }

    componentDidMount() {
        const { handleRestOfContext, originalContext, tabKey } = this.props;

        handleRestOfContext?.(originalContext, tabKey);
        this.setState({
            isInBrowser: true,
            isMobile: isMediumNarrowDown(),
        });

        if (HAS_WINDOW) {
            this.setState({
                // set true if localStorage is supported and user already saw feedback form
                // also, set true if localStorage is NOT supported. We don't want to continually
                // show the feedback form.
                isLocalStorageRecFeedbackViewed: isLocalStorageSupported()
                    ? window?.localStorage?.getItem(
                          'search:recFeedback:viewed',
                      ) === 'true'
                    : true,
            });
        }
    }

    componentDidUpdate(prevProps: HomeBaseContainerProps) {
        const { app, userId } = this.props;
        const prevUserId = prevProps?.userId;

        //Only update user when the userId changes
        if (
            this.state.isInBrowser &&
            !app.loadingState?.isFeLoading &&
            userId !== prevUserId
        ) {
            updateUser({ user: { userID: userId } });
        }
    }

    canShowRecFeedbackForm() {
        return (
            isFeatureFlagRecFeedbackStatusPass &&
            this.props.tabKey === 'for_you' &&
            !this.state.isLocalStorageRecFeedbackViewed
        );
    }

    render() {
        const { header } = this.props;
        const {
            featureFlags,
            country_popular_cities,
            top_destinations_for_tld,
        } = this.props.app;

        const useNewHeader = header?.forceSpecialStyles;
        const enableHomeBottomShelfContainer =
            featureFlags.enablePopularCitiesHomepage ||
            featureFlags.enableTopDestinationsHomepage;

        const _env = {
            ...this.props.env,
            loginUrl: fixLoginUrl(this.props.env?.loginUrl || ''),
            signinUrl: fixLoginUrl(this.props.env?.signinUrl || ''),
            signupUrl: fixLoginUrl(this.props.env?.signupUrl || ''),
        };

        // During the server render, because of Varnish, we want to render the page
        // as a logged-out user. Once we are in the browser, we have the props for the user
        // and we want to update the page. Since most of our components do not keep this in mind,
        // we basically have to do it with componentDidMount or else we get weird markup mismatch issues.
        // Once we're in the browser, we'll go to use the normal props.
        // See also the ConsumerHeader/index.js
        const overrideProps = this.state.isInBrowser
            ? {
                  env: _env,
              }
            : {
                  env: {
                      ..._env,
                      showCookieHeader: false,
                  },
                  footer: <div />,
                  user: {
                      isAuthenticated: false,
                      publicId: null,
                      canCreateEvents: false,
                  },
              };

        return (
            <ConnectedBasePage
                {...this.props}
                {...overrideProps}
                isHomePage={true}
            >
                <HydratedDiv />
                <AttendeeTicketNotification
                    shouldAnimateEntry={true}
                    isAuthenticated={this.props.isAuthenticated}
                    containerStyles={
                        this.state.isMobile
                            ? { margin: '0 auto', top: '120px' }
                            : { right: '32px', top: '120px' }
                    }
                    domNode=""
                    animateEntryDirection="downwards"
                />

                {!useNewHeader ? (
                    <Layout hasHorizontalGutters={true} maxWidth="large">
                        <>
                            <HeaderContainer
                                history={this.props.history}
                                onClick={handleOnClick}
                            />
                        </>
                    </Layout>
                ) : featureFlags.enableHomepageBannerCarousel ? (
                    <HalloweenBannerCarousel />
                ) : (
                    <FullbleedHeaderContainer
                        history={this.props.history}
                        onClick={handleOnClick}
                    />
                )}
                <BrowseContainer />
                {enableHomeBottomShelfContainer && (
                    <HomeBottomShelfContainer
                        ebDomain={this.props.env.ebDomain}
                        localizedCountryName={
                            this.props.env.localeInfo.localized_country_name
                        }
                        country_popular_cities={country_popular_cities}
                        topDestinations={top_destinations_for_tld}
                        enableTopDestinationsHomepage={
                            featureFlags.enableTopDestinationsHomepage
                        }
                        enablePopularCitiesHomepage={
                            featureFlags.enablePopularCitiesHomepage
                        }
                        launchThingsToDoPage={featureFlags.launchThingsToDoPage}
                        enableThingsToDoPhase2={
                            featureFlags.enableThingsToDoPhase2
                        }
                        languageSubdirectory={
                            this.props.env.localeInfo.uses_language_subdirectory
                                ? this.props.env.localeInfo.locale
                                : ''
                        }
                    />
                )}
                {this.canShowRecFeedbackForm() ? (
                    <RecFeedbackContainer />
                ) : null}
            </ConnectedBasePage>
        );
    }
}

const _mapHomeStateToProps = ({
    app,
    user,
    env,
    header,
    content,
}: DiscoverState) => ({
    isMobile: app.isMobile,
    isAuthenticated: user.isAuthenticated,
    userId: user.publicId,
    env,
    app,
    header,
    tabKey: content.browseState?.tabKey,
});

const _mapHomeDispatchToProps = {
    handleSearch: runSearch,
    handleRestOfContext: getRestOfContext,
    updateHeaderImage: updateHeaderImage,
};

type ReduxProps = ConnectedProps<
    typeof _mapHomeStateToProps,
    typeof _mapHomeDispatchToProps
>;
type HomeBaseContainerProps = ReduxProps & OwnProps;

export default connect(
    _mapHomeStateToProps,
    _mapHomeDispatchToProps,
)(HomeBaseContainer);
